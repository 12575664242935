import React from 'react';

export const Header = () => {
	return (
		<header className="App-header">
			<h2 className="light-text">EPIC GAMES STORE EXCLUSIVE</h2>
			<h1>COUNTDOWN</h1>
		</header>
	);
};
